<template>
  <router-view v-if="!loading"></router-view>
  <div v-else class="text-center text-warning my-3">
    <b-spinner class="align-middle"></b-spinner>
    <br />
    <strong>Chargement en cours...</strong>
  </div>
</template>

<script>
import UtilsService from "../../services/utils.service";
export default {
  name: "RecoltesMainView",
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    $route(to) {
      // Si on accède à une campagne depuis le menu, on recharge tout
      // La même campagne ou une autre peu importe
      // Par contre on ne fait rien si on change juste d'onglet !
      if (to.name === "recolte") {
        this.revalidateAndPush(to);
      }
    },
  },
  mounted() {
    this.revalidateAndPush(this.$route);
  },
  methods: {
    async revalidateAndPush(route) {
      // IMPORTANT Délayer le montage du composant enfant tant que le store n'a pas été actualisé
      // Sinon il se charge avec des données périmées lors d'un accès direct à sa route (via F5 par exemple)
      this.loading = true;
      await this.$store.dispatch("decl/load", route.params.codeRecolte);
      this.loading = false;

      // Si on est déjà sur la route d'un composant enfant, laisser faire
      if (route.name !== "recolte") {
        return;
      }

      // Si parcellaire non initialisé, rediriger vers l'onglet Parcelles
      if (this.$store.state.decl.parcelles.length === 0) {
        return this.$router.replace({
          name: "parcelles",
          params: { codeRecolte: route.params.codeRecolte },
        });
      }

      // A partir de juillet, rediriger vers l'onglet Moisson
      let currentDate = UtilsService.dateToIsoSqlDate(new Date());
      let moissonDate = `${route.params.codeRecolte}-07-01`;
      if (currentDate.localeCompare(moissonDate) >= 0) {
        return this.$router.replace({
          name: "moisson",
          params: { codeRecolte: route.params.codeRecolte },
        });
      }

      // Sinon rediriger vers l'onglet Emblavements
      return this.$router.replace({
        name: "emblavements",
        params: { codeRecolte: route.params.codeRecolte },
      });
    },
  },
};
</script>
